<template>
  <Dialog
    header="Información del Producto Financiero"
    v-model:visible="displayModalInfo"
    :style="{width: '50vw'}"
    :maximizable="true"
    :modal="true"
    :closable="false"
    :breakpoints="{'960px': '90vw'}"
  >
    <div class="grid xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-2 sm:grid-cols-2 gap-4">
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-danger mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="university" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Banco</span>
          <span class="text-gray-700 font-bold text-sm">{{ info.bank }}</span>
        </div>
      </div>
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-primary mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="credit-card" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Linea de crédito</span>
          <span class="text-gray-700 font-bold text-sm">{{ info.typeObligation }}</span>
        </div>
      </div>
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-dark mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="hashtag" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Número de la Obligación</span>
          <span class="text-gray-700 font-bold text-sm">{{ info.obligationNumber }}</span>
        </div>
      </div>
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-warning mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="calendar-alt" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Fecha Desembolso</span>
          <span class="text-gray-700 font-bold text-sm">{{ info.disbursementDate }}</span>
        </div>
      </div>
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-success mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="money-bill" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Desembolso</span>
          <InputNumber
            v-if="info.isGenerate"
            id="disbursement"
            class="p-inputtext-xs"
            v-model="disbursement"
            mode="currency"
            currency="COP"
            locale="es-CO"
            minFractionDigits="0"
            maxFractionDigits="0"
            @input="changeDisbursment"
          />
          <span v-else class="text-gray-700 font-bold text-sm">{{ $h.formatCurrency(parseInt(info.disbursement)) }}</span>
        </div>
      </div>
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-info mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="percentage" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Tasa EA</span>
          <InputText
            v-if="info.isGenerate"
            type="text"
            placeholder="Small"
            class="p-inputtext-xs"
            v-model="rateEA"
          />
          <span v-else class="text-gray-700 font-bold text-xs">{{ info.rateEA }}%</span>
        </div>
      </div>
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-danger mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="hashtag" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Plazo</span>
          <InputText
            v-if="info.isGenerate"
            id="term"
            type="number"
            class="p-inputtext-xs"
            v-model="term"
          />
          <span v-else class="text-gray-700 font-bold text-sm">{{ info.term }}</span>
        </div>
      </div>
      <div class="col-span-2 flex">
        <div class="symbol symbol-50 symbol-light-dark mr-5">
        <span class="symbol-label">
          <font-awesome-icon class="text-2xl" icon="calendar-alt" />
        </span>
        </div>
        <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
          <span class="text-gray-500 text-xs">Periodicidad</span>
          <span class="text-gray-700 font-bold text-sm">{{ info.periodicity }}</span>
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalInfo" class="p-button-danger"/>
    </template>
  </Dialog>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'modalInfo',
  props: {
    modalActive: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object
    }
  },
  setup (props, context) {
    const displayModalInfo = ref(false)
    const store = useStore()

    const closeModalInfo = () => {
      context.emit('updateModal', false)
    }

    /* TODO: Escuchas los cambios de las props */
    watch(() => props.modalActive, (newValue, oldValue) => {
      displayModalInfo.value = newValue
    })

    /**/
    const disbursement = computed({
      get () {
        return props.info.disbursement
      },
      set (value) {
        store.commit('infoFinancialProductAmortization/setDisbursment', value)
      }
    })
    /**/
    const rateEA = computed({
      get () {
        return props.info.rateEA
      },
      set (value) {
        let val = 0
        if (value.length > 0) {
          val = value
        }
        store.commit('infoFinancialProductAmortization/setRateEA', val)
      }
    })
    /**/
    const term = computed({
      get () {
        return props.info.term
      },
      set (value) {
        store.commit('infoFinancialProductAmortization/setTerm', value)
      }
    })

    return {
      displayModalInfo,
      closeModalInfo,
      disbursement,
      rateEA,
      term
    }
  }
}
</script>

<style scoped>

</style>
